import React from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";
import _ from "lodash";
import { Link } from "gatsby";
import { formatImagePositionStr, titleCase, isExternal } from "../utils";

import Hero from "./Hero";
import Icon from "./Icon";
import holdTicketWhite from "../images/hold_ticket_white.png";

const Container = styled.div`
  > * {
    ${tw`[&:not(:last-child)]:mb-5`}
  }
`;
const Title = styled.h4`
  ${tw`
    bg-[#EC1F27] text-white font-bold text-center
    p-2.5
  `}
  background-color: ${(props) => props.color};

  ${({ $noBg }) => $noBg && tw`bg-transparent text-black p-0`}
`;
const Tabs = styled.div`
  ${tw`flex flex-wrap gap-2.5 w-full justify-center`}
`;
const IconContainer = styled.span`
  ${tw`min-w-[24px] w-6 h-6 mr-1`}
  & > .material-icons {
    ${tw`text-2xl [line-height: 24px]`}
  }
`;
const GatsbyLink = styled(Link)`
  ${tw`
    w-full sm:w-max
    // flex-[1 1 23%] 
    flex items-center justify-center 
    py-1 px-2 
    border border-gray-300 rounded
    bg-black whitespace-nowrap
    text-white hover:bg-gray-700
    transition-[background-color] duration-200
  `}
`;
const ExternalLink = styled.a`
  ${tw`
    w-full sm:w-max
    // flex-[1 1 23%] 
    flex items-center justify-center 
    py-1 px-2 
    border border-gray-300 rounded
    bg-black whitespace-nowrap
    text-white hover:bg-gray-700
    transition-[background-color] duration-200
  `}
`;

const ImageContainer = styled.div`
  ${tw`w-6 h-6 inline`}
`;

const Header = ({ title, heroData, tabsData, color, site }) => {
  const { pageImage, pageImagePosition } = heroData || {};
  const heroImgPos = formatImagePositionStr(pageImagePosition);

  return (
    <Container>
      {title && <Title color={color}>{title}</Title>}
      {pageImage && (
        <Hero
          img={pageImage}
          imgPosition={heroImgPos}
          imgHeight={250}
          color={color}
        />
      )}
      {!_.isEmpty(tabsData) && (
        <Tabs>
          {tabsData.map(({ label, url, icon }, idx) => {
            const isSingleGameTab = url === "/tickets/single-game";
            return isExternal(url) ? (
              <ExternalLink href={url} target="_blank" key={idx}>
                {isSingleGameTab && site === "dawgs" && (
                  <ImageContainer>
                    <img
                      src={holdTicketWhite}
                      alt="single-game"
                      style={{ width: "100%", height: "auto" }}
                      loading="lazy"
                    />
                  </ImageContainer>
                )}
                {icon &&
                  ((site === "dawgs" && !isSingleGameTab) ||
                    site !== "dawgs") && (
                    <IconContainer>
                      <Icon name={icon} />
                    </IconContainer>
                  )}
                <span>{titleCase(label)}</span>
              </ExternalLink>
            ) : (
              <GatsbyLink to={url} key={idx}>
                {site === "dawgs" && isSingleGameTab && (
                  <ImageContainer>
                    <img
                      src={holdTicketWhite}
                      alt="single-game"
                      style={{ width: "100%", height: "auto" }}
                      loading="lazy"
                    />
                  </ImageContainer>
                )}
                {icon &&
                  ((site === "dawgs" && !isSingleGameTab) ||
                    site !== "dawgs") && (
                    <IconContainer>
                      <Icon name={icon} />
                    </IconContainer>
                  )}
                <span>{titleCase(label)}</span>
              </GatsbyLink>
            );
          })}
        </Tabs>
      )}
    </Container>
  );
};

export default Header;
